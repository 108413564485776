//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'
import { flowableCategoryPage, flowableCategoryDelete } from '@/api/modular/flowable/categoryManage'
import addForm from './addForm'
import editForm from './editForm'
export default {
  components: {
    XCard,
    STable,
    addForm,
    editForm
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '分類名稱',
          dataIndex: 'name'
        },
        {
          title: '唯一編碼',
          dataIndex: 'code'
        },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '備註',
          dataIndex: 'remark'
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return flowableCategoryPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  created () {
    if (this.hasPerm('flowableCategory:edit') || this.hasPerm('flowableCategory:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    /**
     * 刪除
     */
    flowableCategoryDelete (record) {
      flowableCategoryDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
