//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { flowableCategoryEdit, flowableCategoryDetail } from '@/api/modular/flowable/categoryManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      detail: '',
      editDisabled: false
    }
  },
  methods: {
    // 初始化方法
    edit (record) {
      this.confirmLoading = true
      this.visible = true
      this.flowableCategoryDetail(record)
      setTimeout(() => {
        this.form.setFieldsValue(
          {
            id: record.id,
            name: record.name,
            code: record.code,
            sort: record.sort,
            remark: record.remark
          }
        )
      }, 100)
    },
    /**
     * 詳情獲取writable參數置灰核心參數
     */
    flowableCategoryDetail (record) {
      flowableCategoryDetail(record).then((res) => {
        this.confirmLoading = false
         if (!res.data.writable) {
           this.editDisabled = true
         }
      }).catch((err) => {
        this.$message.error('查看詳情錯誤：' + err.message)
      })
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          flowableCategoryEdit(values).then((res) => {
            this.confirmLoading = false
            if (res.success) {
              this.$message.success('編輯成功')
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('編輯失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    }
  }
}
